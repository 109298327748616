:root { 
--white_A700_7f:#ffffff7f; 
--deep_orange_50:#ffece5; 
--red_900:#701e00; 
--gray_900_99:#24242499; 
--green_800:#0f973d; 
--black_900_3f:#0000003f; 
--green_500:#47b749; 
--gray_50:#f4f7ff; 
--white_A700_cc:#ffffffcc; 
--white_A700_99:#ffffff99; 
--white_A700_33:#ffffff33; 
--black_900_89:#00000089; 
--black_900:#000000; 
--yellow_800:#f3a118; 
--indigo_50_01:#e4e7ec; 
--indigo_50_02:#e6edf9; 
--yellow_900:#dd8f0d; 
--blue_gray_800:#344054; 
--blue_gray_900_01:#1d2639; 
--blue_gray_700:#475267; 
--deep_orange_500:#f4652f; 
--blue_gray_900:#343330; 
--gray_900_02:#101828; 
--gray_700:#565656; 
--blue_gray_100:#d0d4dd; 
--blue_700:#1571d8; 
--blue_gray_300:#98a1b2; 
--indigo_50:#e5ecf8; 
--blue_gray_500:#667185; 
--gray_900:#1f242f; 
--gray_900_01:#001633; 
--black_900_0f:#0000000f; 
--green_50:#e7f6ec; 
--green_500_01:#3ac656; 
--gray_100:#f1f5ff; 
--white_A700_87:#ffffff87; 
--gray_100_01:#f6f6f6; 
--indigo_800_35:#20439135; 
--white_A700:#ffffff; 
--indigo_800:#204391; 
--black_900_16:#00000016; 
}